/* Redux */
import { createAction } from '@reduxjs/toolkit';

/* Project */
import SchedulerStepsTypes from './schedulerSteps.types';

export const controlsChanged = createAction(
  SchedulerStepsTypes.CONTROLS_CHANGED,
  ({ name, value }) => ({
    payload: {
      [name]: value,
    },
  }),
);

export const toggleContactDialog = createAction(SchedulerStepsTypes.TOGGLE_CONTACT_FORM);
export const toggleAddContact = createAction(SchedulerStepsTypes.TOGGLE_ADD_CONTACT);
export const toggleEdit = createAction(SchedulerStepsTypes.TOGGLE_EDIT);

export const selectContact = createAction(SchedulerStepsTypes.SELECT_CONTACT, (payload) => ({
  payload,
}));

export const closeContactDialog = createAction(SchedulerStepsTypes.CLOSE_CONTACT_DIALOG);
export const clearContact = createAction(SchedulerStepsTypes.CLEAR_CONTACT);
export const removeContact = createAction(SchedulerStepsTypes.REMOVE_CONTACT, (payload) => ({
  payload,
}));
export const editDateError = createAction(SchedulerStepsTypes.EDIT_DATE_ERROR);
export const closeEditDateError = createAction(SchedulerStepsTypes.CLOSE_EDIT_DATE_ERROR);
export const getDocument = createAction(SchedulerStepsTypes.GET_VOUCHER_DOCUMENT, (payload) => ({
  payload,
}));
export const getDocumentSuccess = createAction(SchedulerStepsTypes.GET_VOUCHER_DOCUMENT_SUCCESS);
export const getDocumentFail = createAction(SchedulerStepsTypes.GET_VOUCHER_DOCUMENT_FAIL);
export const toggleTermsAndConditions = createAction(
  SchedulerStepsTypes.TOGGLE_TERMS_AND_CONDITIONS,
);
export const toggleTermsErrors = createAction(SchedulerStepsTypes.TERMS_AND_CONDITIONS_ERROR);
export const addUserContact = createAction(SchedulerStepsTypes.ADD_USER_CONTACT, (payload) => ({
  payload,
}));
export const emptyDateError = createAction(SchedulerStepsTypes.EMPTY_DATE_ERROR);
export const closeEmptyDateError = createAction(SchedulerStepsTypes.CLOSE_EMPTY_DATE_ERROR);
export const selectReschedule = createAction(SchedulerStepsTypes.SELECT_RESCHEDULE, (payload) => ({
  payload,
}));

export const getUserVehicle = createAction(
  SchedulerStepsTypes.GET_USER_VEHICLE_FROM_API,
  (payload) => ({
    payload,
  }),
);
export const getUserVehicleSuccess = createAction(
  SchedulerStepsTypes.GET_USER_VEHICLE_FROM_API_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const getUserVehicleWithConectaApi = createAction(
  SchedulerStepsTypes.GET_USER_VEHICLE_FROM_API_CONECTA,
  (payload) => ({
    payload,
  }),
);

export const getUserVehicleWithConectaApiSuccess = createAction(
  SchedulerStepsTypes.GET_USER_VEHICLE_FROM_API_CONECTA_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const getUserData = createAction(SchedulerStepsTypes.GET_USER_DATA_FROM_API, (payload) => ({
  payload,
}));
export const getUserDataSuccess = createAction(
  SchedulerStepsTypes.GET_USER_DATA_FROM_API_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const getDates = createAction(SchedulerStepsTypes.GET_SCHEDULE_DATES);
export const getDatesSuccess = createAction(
  SchedulerStepsTypes.GET_SCHEDULE_DATES_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const submitSchedule = createAction(SchedulerStepsTypes.SUBMIT_SCHEDULE, (payload) => ({
  payload,
}));

export const submitScheduleSuccess = createAction(
  SchedulerStepsTypes.SUBMIT_SCHEDULE_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const toggleSchedulerError = createAction(SchedulerStepsTypes.TOGGLE_ERROR, (payload) => ({
  payload,
}));

export const clearSchedulerError = createAction(SchedulerStepsTypes.CLEAR_ERRORS);

export const submitReSchedule = createAction(SchedulerStepsTypes.SUBMIT_RESCHEDULE, (payload) => ({
  payload,
}));

export const submitReScheduleSuccess = createAction(
  SchedulerStepsTypes.SUBMIT_RESCHEDULE_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const resetIsFindedVehicle = createAction(
  SchedulerStepsTypes.RESET_IS_FINDED_VEHICLE,
  (payload) => ({
    payload,
  }),
);

export const resetIsFindedUser = createAction(
  SchedulerStepsTypes.RESET_IS_FINDED_USER,
  (payload) => ({
    payload,
  }),
);

export const setTechnicians = createAction(SchedulerStepsTypes.SET_TECHNICIANS, (payload) => ({
  payload,
}));

export const clearVehicleFormValues = createAction(
  SchedulerStepsTypes.CLEAR_VEHICLE_FORM_VALUE,
  (payload) => ({
    payload,
  }),
);

export const clearOwnerVehicleFormValues = createAction(
  SchedulerStepsTypes.CLEAR_OWNER_VEHICLE_FORM_VALUE,
  (payload) => ({
    payload,
  }),
);

export const onChangeImage = createAction(
  SchedulerStepsTypes.CONTROLS_CHANGED_IMAGE,
  (payload) => ({
    payload,
  }),
);

export const setOpenNotEmailContactDialog = createAction(
  SchedulerStepsTypes.SET_OPEN_NOT_EMAIL_CONTACT_DIALOG,
  (payload) => ({
    payload,
  }),
);

export const updateContact = createAction(SchedulerStepsTypes.UPDATE_CONTACT, (payload) => ({
  payload,
}));

export const getTechniciansAndAvailabilities = createAction(
  SchedulerStepsTypes.GET_TECHNICIANS_AND_AVAILABILITIES_FROM_API_CONECTA,
  (payload) => ({
    payload,
  }),
);
export const getTechniciansAndAvailabilitiesSuccess = createAction(
  SchedulerStepsTypes.GET_TECHNICIANS_AND_AVAILABILITIES_FROM_API_CONECTA_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const setControlsErrors = createAction(
  SchedulerStepsTypes.SET_CONTROLS_ERRORS,
  (payload) => ({
    payload,
  }),
);

export const clearControlsErrors = createAction(SchedulerStepsTypes.CLEAR_CONTROLS_ERRORS);

export const setMonth = createAction(SchedulerStepsTypes.SET_MONTH, (payload) => ({
  payload,
}));

export const resetStepsState = createAction(SchedulerStepsTypes.RESET_STEPS_STATE);

export const getAvailabilities = createAction(
  SchedulerStepsTypes.GET_AVAILABILITIES_FROM_API_CONECTA,
  (payload) => ({
    payload,
  }),
);

export const getAvailabilitiesSuccess = createAction(
  SchedulerStepsTypes.GET_AVAILABILITIES_FROM_API_CONECTA_SUCCESS,
  (payload) => ({
    payload,
  }),
);

export const clearSelectedDate = createAction(SchedulerStepsTypes.CLEAR_SELECTED_DATE);

export const selectServiceDates = createAction(
  SchedulerStepsTypes.SELECT_SERVICE_DATES,
  (payload) => ({
    payload,
  }),
);

export const getSchedulerResponse = createAction(
  SchedulerStepsTypes.GET_SCHEDULER_RESPONSE,
  (payload) => ({
    payload,
  }),
);

export const schedulerInternalError = createAction(
  SchedulerStepsTypes.SCHEDULER_INTERNAL_ERROR,
  (payload) => ({
    payload,
  }),
);

export const closeInternalError = createAction(SchedulerStepsTypes.CLOSE_INTERNAL_ERROR);
export const clearScheduleData = createAction(SchedulerStepsTypes.CLEAR_SCHEDULE_DATA);
export const clearDate = createAction(SchedulerStepsTypes.CLEAR_DATE);

export const openAppointmentInfo = createAction(
  SchedulerStepsTypes.OPEN_APPOINTMENT_INFO,
  (payload) => ({
    payload,
  }),
);

export const closeAppointmentInfo = createAction(SchedulerStepsTypes.CLOSE_APPOINTMENT_INFO);

export const clearTechnician = createAction(SchedulerStepsTypes.CLEAR_TECHNICIAN);
export const enableClientForm = createAction(SchedulerStepsTypes.CLIENT_FORM_ENABLED);

const SchedulerStepsActions = {
  controlsChanged,
  toggleContactDialog,
  toggleAddContact,
  toggleEdit,
  selectContact,
  closeContactDialog,
  clearContact,
  removeContact,
  editDateError,
  closeEditDateError,
  getDocument,
  getDocumentSuccess,
  getDocumentFail,
  toggleTermsAndConditions,
  toggleTermsErrors,
  addUserContact,
  emptyDateError,
  closeEmptyDateError,
  selectReschedule,
  getUserVehicle,
  getUserVehicleSuccess,
  getUserData,
  getUserDataSuccess,
  getDates,
  getDatesSuccess,
  submitSchedule,
  submitScheduleSuccess,
  toggleSchedulerError,
  clearSchedulerError,
  submitReSchedule,
  submitReScheduleSuccess,
  resetIsFindedVehicle,
  resetIsFindedUser,
  setTechnicians,
  clearVehicleFormValues,
  clearOwnerVehicleFormValues,
  getUserVehicleWithConectaApi,
  getUserVehicleWithConectaApiSuccess,
  setOpenNotEmailContactDialog,
  getTechniciansAndAvailabilities,
  getTechniciansAndAvailabilitiesSuccess,
  setControlsErrors,
  clearControlsErrors,
  setMonth,
  resetStepsState,
  getAvailabilities,
  getAvailabilitiesSuccess,
  clearSelectedDate,
  selectServiceDates,
  getSchedulerResponse,
  schedulerInternalError,
  clearScheduleData,
  openAppointmentInfo,
  closeAppointmentInfo,
  clearTechnician,
  enableClientForm,
};

export default SchedulerStepsActions;
