import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* React router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* Project */
import Loader from 'content/shared/Loader/Loader';
import { getScheduleStateFromApi, setView } from 'content/app.actions';
import ScheduleWelcomeButton from './ScheduleWelcomeButton';
import SelectServiceInputButton from './SelectServiceInputButton';
import { controlsChanged, resetWelcomeScreenControls } from '../welcomeScreens.actions';
import homeStyles from '../home.styles';
import '../styles.css';
import colors from 'utils/colors';
import RescheduleForm from './RescheduleForm';
import AnnulmentForm from './AnnulmentForm';
import { resetStepsState } from 'content/features/schedulerSteps/schedulerSteps.actions';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';

function ScheduleWelcome() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileRadio = useMediaQuery(theme.breakpoints.down('sm'));

  const objects = useSelector((state) => state.app.objects);
  const requestLoading = useSelector((state) => state.app.requestLoading);
  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);
  const controls = useSelector((state) => state.welcomeScreen.controls);
  const loading = useSelector((state) => state.welcomeScreen.loading);
  const view = useSelector((state) => state.app.view);

  useEffect(() => {
    dispatch(getScheduleStateFromApi());
  }, []);

  const services = useMemo(
    () => Object.keys(objects?.services).map((key) => objects?.services[key]),
    [objects],
  );

  const onChangeText = useCallback((data) => {
    const { name, value } = data.target;
    dispatch(controlsChanged({ name, value }));
  }, []);

  const handleSubmit = () => {
    dispatch(setView({ value: 'schedule' }));
    navigate(`${selectedService}/steps`);
  };

  const [divHeight, setDivHeight] = useState(0);
  const [selectedServiceForm, setSelectedServiceForm] = useState('scheduling');
  const ref = useRef(null);

  useEffect(() => {
    setDivHeight(ref?.current?.clientHeight || 0);
  }, []);
  useEffect(() => {
    if (view) setSelectedServiceForm(view);
  }, [view]);

  const handleSelectService = (e) => {
    const service = e.target.value;
    setSelectedServiceForm(service);
    // Reset all states
    dispatch(resetWelcomeScreenControls());
    dispatch(resetStepsState());
    dispatch(resetAnnulmentState());

    dispatch(controlsChanged({ name: 'preSelectedService', value: service }));
    dispatch(setView({ value: service }));
  };

  return (
    <Grid>
      <Loader loading={requestLoading} height={divHeight}>
        <Grid ref={ref}>
          <Grid mt={6}>
            <Typography color="primary" variant="h4" sx={homeStyles.title}>
              Tu Portal de Agendamiento Taller
            </Typography>
            <Typography mt={3} variant="h5" color="primary">
              ¿Qué deseas hacer?
            </Typography>
            <Typography color="primary">
              Selecciona el tipo de servicio que deseas realizar.
            </Typography>
          </Grid>

          <FormControl>
            <RadioGroup
              row
              value={selectedServiceForm}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group">
              <FormControlLabel
                sx={{ marginRight: '40px', color: colors.blue }}
                value="scheduling"
                color="lightBlue"
                onChange={handleSelectService}
                control={
                  <Radio
                    sx={{
                      color: colors.lightBlue,
                      '&.Mui-checked': {
                        color: colors.lightBlue,
                      },
                    }}
                  />
                }
                label="Agendar"
              />
              <FormControlLabel
                sx={{ marginRight: '40px', color: colors.blue }}
                value="reschedule"
                onChange={handleSelectService}
                control={
                  <Radio
                    sx={{
                      color: colors.lightBlue,
                      '&.Mui-checked': {
                        color: colors.lightBlue,
                      },
                    }}
                  />
                }
                label="Reagendar"
              />
              <FormControlLabel
                value="annulment"
                sx={{ color: colors.blue }}
                onChange={handleSelectService}
                control={
                  <Radio
                    sx={{
                      color: colors.lightBlue,
                      '&.Mui-checked': {
                        color: colors.lightBlue,
                      },
                    }}
                  />
                }
                label="Anular Hora"
              />
            </RadioGroup>
          </FormControl>

          {selectedServiceForm === 'scheduling' && (
            <>
              <Grid>
                <Typography mt={3} variant="h5" color="primary">
                  Agendamiento Taller
                </Typography>
                <Typography color="primary">
                  Selecciona el tipo de servicio que deseas agendar.
                </Typography>
              </Grid>
              <SelectServiceInputButton
                isMobile={isMobile}
                services={services}
                onChangeText={onChangeText}
                homeStyles={homeStyles}
              />
              <Grid item xs={12} sx={homeStyles.actionsContainer}>
                <ScheduleWelcomeButton isMobile={isMobile} handleSubmit={handleSubmit} />
              </Grid>
            </>
          )}

          {selectedServiceForm === 'reschedule' && (
            <>
              <Grid>
                <Typography mt={3} variant="h5" color="primary">
                  Reagendamiento Taller
                </Typography>
                <Typography color="primary">Selecciona.</Typography>
              </Grid>
              <RescheduleForm
                controls={controls}
                loading={loading}
                homeStyles={homeStyles}
                isMobile={isMobileRadio}
              />
            </>
          )}

          {selectedServiceForm === 'annulment' && (
            <>
              <Grid>
                <Typography mt={3} variant="h5" color="primary">
                  Anular Tu Hora
                </Typography>
                <Typography color="primary">Selecciona.</Typography>
              </Grid>
              <AnnulmentForm
                controls={controls}
                loading={loading}
                homeStyles={homeStyles}
                isMobile={isMobileRadio}
              />
            </>
          )}
        </Grid>
      </Loader>
    </Grid>
  );
}

export default ScheduleWelcome;
