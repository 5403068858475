const BASE = '[SchedulerSteps]';

const SchedulerStepsTypes = {
  CONTROLS_CHANGED: `${BASE} CONTROLS_CHANGED`,
  CONTROLS_CHANGED_IMAGE: `${BASE} CONTROLS_CHANGED_IMAGE`,
  TOGGLE_ADD_CONTACT: `${BASE} TOGGLE_ADD_CONTACT`,
  TOGGLE_CONTACT_FORM: `${BASE} TOGGLE_CONTACT_FORM`,
  TOGGLE_EDIT: `${BASE} TOGGLE_EDIT`,
  SELECT_CONTACT: `${BASE} SELECT_CONTACT`,
  CLOSE_CONTACT_DIALOG: `${BASE} CLOSE_CONTACT_DIALOG`,
  CLEAR_CONTACT: `${BASE} CLEAR_CONTACT`,
  REMOVE_CONTACT: `${BASE} REMOVE_CONTACT`,
  EDIT_DATE_ERROR: `${BASE} EDIT_DATE_ERROR`,
  CLOSE_EDIT_DATE_ERROR: `${BASE} CLOSE_EDIT_DATE_ERROR`,
  GET_VOUCHER_DOCUMENT: `${BASE} GET_VOUCHER_DOCUMENT`,
  GET_VOUCHER_DOCUMENT_SUCCESS: `${BASE} GET_VOUCHER_DOCUMENT_SUCCESS`,
  GET_VOUCHER_DOCUMENT_FAIL: `${BASE} GET_VOUCHER_DOCUMENT_FAIL`,
  TOGGLE_TERMS_AND_CONDITIONS: `${BASE} TOGGLE_TERMS_AND_CONDITIONS`,
  TERMS_AND_CONDITIONS_ERROR: `${BASE} TERMS_AND_CONDITIONS_ERROR`,
  ADD_USER_CONTACT: `${BASE} ADD_USER_CONTACT`,
  EMPTY_DATE_ERROR: `${BASE} EMPTY_DATE_ERROR`,
  CLOSE_EMPTY_DATE_ERROR: `${BASE} CLOSE_EMPTY_DATE_ERROR`,
  SELECT_RESCHEDULE: `${BASE} SELECT_RESCHEDULE`,
  GET_USER_VEHICLE_FROM_API: `${BASE} GET_USER_VEHICLE_FROM_API`,
  GET_USER_VEHICLE_FROM_API_SUCCESS: `${BASE} GET_USER_VEHICLE_FROM_API_SUCCESS`,
  GET_USER_DATA_FROM_API: `${BASE} GET_USER_DATA_FROM_API`,
  GET_USER_DATA_FROM_API_SUCCESS: `${BASE} GET_USER_DATA_FROM_API_SUCCESS`,
  GET_SCHEDULE_DATES: `${BASE} GET_SCHEDULE_DATES`,
  GET_SCHEDULE_DATES_SUCCESS: `${BASE} GET_SCHEDULE_DATES_SUCCESS`,
  SUBMIT_SCHEDULE: `${BASE} SUBMIT_SCHEDULE`,
  SUBMIT_SCHEDULE_SUCCESS: `${BASE} SUBMIT_SCHEDULE_SUCCESS`,
  TOGGLE_ERROR: `${BASE} TOGGLE_ERROR`,
  CLEAR_ERRORS: `${BASE} CLEAR_ERRORS`,
  SUBMIT_RESCHEDULE: `${BASE} SUBMIT_RESCHEDULE`,
  SUBMIT_RESCHEDULE_SUCCESS: `${BASE} SUBMIT_RESCHEDULE_SUCCESS`,
  RESET_IS_FINDED_VEHICLE: `${BASE} RESET_IS_FINDED_VEHICLE`,
  RESET_IS_FINDED_USER: `${BASE} RESET_IS_FINDED_USER`,
  SET_TECHNICIANS: `${BASE} SET_TECHNICIANS`,
  CLEAR_VEHICLE_FORM_VALUE: `${BASE} CLEAR_VEHICLE_FORM_VALUE`,
  CLEAR_OWNER_VEHICLE_FORM_VALUE: `${BASE} CLEAR_OWNER_VEHICLE_FORM_VALUE`,
  GET_USER_VEHICLE_FROM_API_CONECTA: `${BASE} GET_USER_VEHICLE_FROM_API_CONECTA`,
  GET_USER_VEHICLE_FROM_API_CONECTA_SUCCESS: `${BASE} GET_USER_VEHICLE_FROM_API_CONECTA_SUCCESS`,
  SET_OPEN_NOT_EMAIL_CONTACT_DIALOG: `${BASE} SET_OPEN_NOT_EMAIL_CONTACT_DIALOG`,
  UPDATE_CONTACT: `${BASE} UPDATE_CONTACT`,
  GET_TECHNICIANS_AND_AVAILABILITIES_FROM_API_CONECTA: `${BASE} GET_TECHNICIANS_AND_AVAILABILITIES_FROM_API_CONECTA`,
  GET_TECHNICIANS_AND_AVAILABILITIES_FROM_API_CONECTA_SUCCESS: `${BASE} GET_TECHNICIANS_AND_AVAILABILITIES_FROM_API_CONECTA_SUCCESS`,
  SET_CONTROLS_ERRORS: `${BASE} SET_CONTROLS_ERRORS`,
  CLEAR_CONTROLS_ERRORS: `${BASE} CLEAR_CONTROLS_ERRORS`,
  SET_MONTH: `${BASE} SET_MONTH`,
  RESET_STEPS_STATE: `${BASE} RESET_STEPS_STATE`,
  GET_AVAILABILITIES_FROM_API_CONECTA: `${BASE} GET_AVAILABILITIES_FROM_API_CONECTA`,
  GET_AVAILABILITIES_FROM_API_CONECTA_SUCCESS: `${BASE} GET_AVAILABILITIES_FROM_API_CONECTA_SUCCESS`,
  CLEAR_TECHNICIAN_AVAILABILITIES: `${BASE} CLEAR_TECHNICIAN_AVAILABILITIES`,
  CLEAR_SELECTED_DATE: `${BASE} CLEAR_SELECTED_DATE`,
  SELECT_SERVICE_DATES: `${BASE} SELECT_SERVICE_DATES`,
  GET_SCHEDULER_RESPONSE: `${BASE} GET_SCHEDULER_RESPONSE`,
  SCHEDULER_INTERNAL_ERROR: `${BASE} SCHEDULER_INTERNAL_ERROR`,
  CLOSE_INTERNAL_ERROR: `${BASE} CLOSE_INTERNAL_ERROR`,
  CLEAR_SCHEDULE_DATA: `${BASE} CLEAR_SCHEDULE_DATA`,
  CLEAR_DATE: `${BASE} CLEAR_DATE`,
  OPEN_APPOINTMENT_INFO: `${BASE} OPEN_APPOINTMENT_INFO`,
  CLOSE_APPOINTMENT_INFO: `${BASE} CLOSE_APPOINTMENT_INFO`,
  CLEAR_TECHNICIAN: `${BASE} CLEAR_TECHNICIAN`,

  CLIENT_FORM_ENABLED: `${BASE} CLIENT_FORM_ENABLED`,
};

export default SchedulerStepsTypes;
