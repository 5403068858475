import React, { useDebugValue, useEffect } from 'react';

/* Project */
import StyledContainer from 'content/containers/StyledContainer';
import CancelAppointment from 'content/features/annulmentSteps/CancelAppointment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetAnnulmentState } from 'content/features/annulmentSteps/annulmentSteps.actions';
import { resetWelcomeScreenControls } from 'content/features/welcomeScreens/welcomeScreens.actions';
import { Box, Grid, Typography } from '@mui/material';
import { getAnnulmentStateFromApi } from 'content/app.actions';

function Annulment({ title }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAnnulmentState());
    dispatch(getAnnulmentStateFromApi());
  }, []);

  const view = useSelector((state) => state.app.view);
  const { clientName, clientRut } = useSelector(
    (state) => state.annulmentSteps.controls.selectedAnnulments,
  );

  useEffect(() => {
    if (view === '') {
      navigate('/annulment');
    }
  }, [view]);

  return (
    <StyledContainer title={title}>
      <Typography color="primary" variant="h5" sx={{ marginBottom: '20px' }}>
        Selecciona un servicio
      </Typography>
      <Grid display="flex" flexDirection="row" justifyContent="flex-start" gap={4}>
        <Box display="flex" gap={1}>
          <Typography color="primary" variant="body1" fontWeight="bold">
            Cliente:
          </Typography>
          <Typography variant="body1" color="primary">
            {clientName}
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Typography variant="body1" color="primary" fontWeight="bold">
            Rut:
          </Typography>
          <Typography variant="body1" color="primary">
            {clientRut}
          </Typography>
        </Box>
      </Grid>
      <CancelAppointment />
    </StyledContainer>
  );
}

export default Annulment;
