/* Material UI */
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import QuizIcon from '@mui/icons-material/Quiz';
/* Router */
import Annulment from 'content/pages/Annulment/Annulment';
import AnnulmentVoucher from 'content/pages/AnnulmentVoucher/AnnulmentVoucher';
import Confirm from 'content/pages/Confirm/Confirm';
import ConfirmValidation from 'content/pages/ConfirmValidation/ConfirmValidation';
import Counter from 'content/pages/Counter/Counter';
import Home from 'content/pages/Home/Home';
import Login from 'content/pages/Login/Login';
import RecoveryPassword from 'content/pages/Recovery/RecoveryPassword';
import RegisterUser from 'content/pages/Register/Register';
import Rescheduler from 'content/pages/Rescheduler/Rescheduler';
import ReschedulerVoucher from 'content/pages/ReschedulerVoucher/ReschedulerVoucher';
import Scheduler from 'content/pages/Scheduler/Scheduler';
import SchedulerVoucher from 'content/pages/SchedulerVoucher/SchedulerVoucher';
import UserTasks from 'content/pages/UserTasks/UserTasks';

const Paths = {
  publics: [
    {
      path: '/',
      name: 'Agendamiento',
      component: Home,
      useLayout: true,
      Icon: DirectionsCarIcon,
      hideInDrawer: false,
      title: 'AGENDAMIENTO TALLER',
    },
    {
      path: '/recovery/*',
      component: RecoveryPassword,
      useLayout: false,
      Icon: null,
      isAuth: true,
      hideInDrawer: true,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      useLayout: false,
      Icon: AccountCircleIcon,
      isAuth: true,
      hideInDrawer: true,
    },
    {
      path: '/register',
      component: RegisterUser,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
    {
      path: '/reschedule',
      name: 'Reagendamiento',
      component: Home,
      useLayout: true,
      Icon: CalendarMonthIcon,
      hideInDrawer: false,
      title: 'REAGENDAMIENTO TALLER',
    },
    {
      path: '/annulment',
      name: 'Anulación',
      component: Home,
      useLayout: true,
      Icon: EventBusyIcon,
      hideInDrawer: false,
      title: 'ANULAR AGENDAMIENTO TALLER',
    },
    {
      path: '/maintenance/steps',
      name: 'schedule-steps',
      component: Scheduler,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
    },
    {
      path: '/diagnosis/steps',
      name: 'schedule-steps',
      component: Scheduler,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
    },
    {
      path: '/schedule/success',
      name: 'schedule-success',
      component: SchedulerVoucher,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
    },
    {
      path: '/annulment/steps',
      name: 'annulment-steps',
      component: Annulment,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
      title: 'ANULAR AGENDAMIENTO TALLER',
    },
    {
      path: '/annulment/success',
      name: 'annulment-success',
      component: AnnulmentVoucher,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
      title: 'ANULAR AGENDAMIENTO TALLER',
    },
    {
      path: '/reschedule/steps',
      name: 'reschedule-steps',
      component: Rescheduler,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
      title: 'REAGENDAMIENTO TALLER',
    },
    {
      path: '/reschedule/success',
      name: 'reschedule-success',
      component: ReschedulerVoucher,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
      title: 'REAGENDAMIENTO TALLER',
    },
    {
      path: '/confirm/:ticketID',
      name: 'confirm',
      component: Confirm,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
      title: 'CONFIRMAR AGENDAMIENTO',
    },
    {
      path: '/confirm/validation',
      name: 'confirm-validation',
      component: ConfirmValidation,
      useLayout: true,
      Icon: LooksOneIcon,
      hideInDrawer: true,
      title: '',
    },
  ],
  redirects: [
    {
      path: '/maintenance/steps/:stepId',
      redirectTo: '/',
    },
    {
      path: '/diagnosis/steps/:stepId',
      redirectTo: '/',
    },
  ],
  shared: [],
  protecteds: [
    {
      path: '/todo',
      name: 'Tareas',
      component: UserTasks,
      Icon: ListAltIcon,
    },
  ],
  independients: [
    {
      path: '/faq',
      name: 'FAQ',
      component: Counter,
      Icon: QuizIcon,
    },
  ],
};

export default Paths;
