import React, { useCallback } from 'react';

/* Material UI */
import { Grid, Typography, Hidden, Button, Box } from '@mui/material';

/* Project */
import textImageValidationSelectedService from 'content/shared/textImageValidationSelectedService';
import SchedulerStepsStyles from '../SchedulerSteps.styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setView } from 'content/app.actions';
import { controlsChanged } from 'content/features/welcomeScreens/welcomeScreens.actions';

const maintenance = `${window.location.origin}/images/taller2.jpg`;
const diagnosis = `${window.location.origin}/images/diagnosis.jpg`;

function ServiceImageSection(props) {
  const { serviceType, item = false, margin = 0, onClick, style, sx } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { textValidation, textValidationImage } = textImageValidationSelectedService[serviceType];

  const handleSubmit = () => {
    const selectedService = onClick();
    dispatch(setView({ value: 'schedule' }));
    navigate(`${selectedService}/steps`);
  };

  return (
    <Hidden mdDown>
      <Grid
        item={item}
        margin={margin}
        onClick={handleSubmit}
        id="image-section"
        style={{
          position: 'relative',
          borderRadius: '15px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '20px',
          ...style,
        }}
        sx={{ ...SchedulerStepsStyles.imageBackground, ...sx }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.8) 20%, rgba(108,108,108,0) 86%, rgba(249,249,249,0) 100%),url(${
              serviceType === 'maintenance' ? maintenance : diagnosis
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'contrast(0.75)', // Adjust contrast as needed
            zIndex: 1,
          }}
        />
        <Typography sx={{ color: '#fff', zIndex: 2 }} variant="h6" gutterBottom>
          {textValidation}
        </Typography>
        <Typography
          sx={{ color: '#fff', whiteSpace: 'break-spaces', zIndex: 2 }}
          variant="body2"
          gutterBottom>
          {textValidationImage}
        </Typography>
        <Button
          onClick={handleSubmit}
          sx={{ alignSelf: 'center', marginTop: '5px', color: 'white', zIndex: 2 }}
          variant="contained"
          color="lightBlue"
          size="medium">
          Selecciona
        </Button>
      </Grid>
    </Hidden>
  );
}

export default ServiceImageSection;
