import React from 'react';

/* React Redux */
import { useSelector } from 'react-redux';

/* Material UI */
import { FormControl, Grid, InputLabel, MenuItem, Select, Box } from '@mui/material';

/* Project */
import ServiceImageSection from 'content/features/schedulerSteps/components/ServiceImageSection';
import textImageValidationSelectedService from 'content/shared/textImageValidationSelectedService';

function SelectServiceInputButton(props) {
  const { isMobile, services, onChangeText, homeStyles } = props;

  const selectedService = useSelector((state) => state.welcomeScreen.controls.selectedService);

  const handleClickCard = (serviceTypeSelected) => {
    onChangeText({ target: { name: 'selectedService', value: serviceTypeSelected } });
    return serviceTypeSelected;
  };

  return (
    <Box width="100%">
      {isMobile ? (
        <Grid xs={12} sm={12} md={4} item sx={homeStyles.container}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Seleccionar servicio</InputLabel>
            <Select
              id="selectedService"
              name="selectedService"
              value={selectedService}
              label="Seleccionar servicio"
              onChange={onChangeText}>
              {services.map((item) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <Grid container item xs={12} minHeight="220px" marginTop="20px" gap={4} flexWrap="nowrap">
          {Object.keys(textImageValidationSelectedService).map((key) => (
            <ServiceImageSection
              key={key}
              serviceType={key}
              item
              onClick={() => handleClickCard(key)}
              sx={homeStyles.serviceCard}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default SelectServiceInputButton;
