import React, { useMemo } from 'react';

/* React redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Grid } from '@mui/material';

/* Project */
import { anyFalseInObject, anyTruhtyInObject, vehicleYearValidation } from 'utils/functions';
import { setActiveStep } from 'content/app.actions';
import { setControlsErrors } from '../../schedulerSteps.actions';
import StepsActionButtons from '../StepsActionButtons';
import ActionsButtonsContainer from '../ActionsButtonsContainer';

const brandValidation = (brand) => (brand !== 'chevrolet' ? 'Debe seleccionar una marca' : '');
const basicRequiredFieldValidation = (field, errorMessage) => (field === '' ? errorMessage : '');
const yearFieldValidation = (year) => (!vehicleYearValidation(year) ? ' Campo año inválido' : '');
// const vinRequiredFieldValidation = (vin) => {
//   const validations = {
//     notEmptyValue: vin === '',
//     notEmptyValueWithTrim: vin.trim().length === 0,
//   };

//   const isInvalidField = anyTruhtyInObject(validations);

//   return isInvalidField ? 'Campo VIN requerido' : '';
// };
const patentRequiredFieldValidation = (patent) => {
  const validations = {
    notEmptyValue: patent === '',
    notEmptyValueWithTrim: patent.trim().length === 0,
  };

  const isInvalidField = anyTruhtyInObject(validations);

  return isInvalidField ? 'Campo patente requerido' : '';
};

const patentFormatRequiredFieldValidation = (patent) => {
  const validations = {
    minLengthValidation: patent?.toString()?.length >= 6,
  };

  const isInvalidField = anyFalseInObject(validations);

  return isInvalidField ? 'Campo patente inválido' : '';
};

const yearRequiredFieldValidation = (year) => {
  const validations = {
    notEmptyValue: year === '',
    notEmptyValueWithTrim: year.trim().length === 0,
    vehicleYearValidation: !vehicleYearValidation(year) && year !== '',
  };

  const isInvalidField = anyTruhtyInObject(validations);

  if (isInvalidField && validations.vehicleYearValidation) {
    return 'Campo año inválido';
  } else if (isInvalidField && validations.notEmptyValue && validations.notEmptyValueWithTrim) {
    return 'Campo año requerido';
  } else {
    return '';
  }
};

function CustomStepsActionButtons() {
  const dispatch = useDispatch();

  const brands = useSelector((state) => state.app.objects.brands);
  const brand = useSelector((state) => state.schedulerSteps.controls.brand);
  const vin = useSelector((state) => state.schedulerSteps.controls.vin);
  const patent = useSelector((state) => state.schedulerSteps.controls.patent);
  const modelId = useSelector((state) => state.schedulerSteps.controls.modelId);
  const versionId = useSelector((state) => state.schedulerSteps.controls.versionId);
  const year = useSelector((state) => state.schedulerSteps.controls.year);

  const brandsData = useMemo(() => Object.keys(brands).map((key) => brands[key]), [brands]);
  const brandValidation = (brand) =>
    brandsData.every((item) => item.name !== brand) ? 'Debe seleccionar una marca' : '';

  const handleNext = (event) => {
    event.preventDefault();

    const newControlsErrors = {
      brandId: '',
      patent: '',
      vin: '',
      modelId: '',
      versionId: '',
      year: '',
    };

    newControlsErrors.brandId = brandValidation(brand);

    // newControlsErrors.vin = vinRequiredFieldValidation(vin);
    // newControlsErrors.vin = basicRequiredFieldValidation(vin, 'Campo VIN requerido');

    newControlsErrors.year = yearFieldValidation(year);
    if (newControlsErrors.patent === '') {
      newControlsErrors.year = yearRequiredFieldValidation(year);
    }

    newControlsErrors.patent = patentRequiredFieldValidation(patent);
    if (newControlsErrors.patent === '') {
      newControlsErrors.patent = patentFormatRequiredFieldValidation(patent);
    }
    newControlsErrors.modelId = basicRequiredFieldValidation(modelId, 'Debe seleccionar un modelo');

    newControlsErrors.versionId = basicRequiredFieldValidation(
      versionId,
      'Debe seleccionar una versión',
    );

    if (newControlsErrors.year === '') {
      newControlsErrors.year = yearRequiredFieldValidation(year);
    }
    // newControlsErrors.year = basicRequiredFieldValidation(year, 'Campo año requerido');

    const isNotValidForm = Object.values(newControlsErrors).some(
      (controlError) => controlError !== '',
    );
    
    return isNotValidForm
      ? dispatch(
          setControlsErrors({
            controlsErrors: {
              ...newControlsErrors,
            },
          }),
        )
      : dispatch(setActiveStep());
  };

  return (
    <ActionsButtonsContainer>
      <StepsActionButtons handleNext={handleNext} />
    </ActionsButtonsContainer>
  );
}

export default CustomStepsActionButtons;
